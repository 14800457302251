





































































































import Vue from "vue";

export default Vue.extend({
  name: "main-section",
  data() {
    return {
      triggerAnimation: false,
    };
  },
  props: {
    text: String,
  },
  computed: {
    truckImageWidth(): string {
      const { sm } = this.$vuetify.breakpoint;
      let result = "";

      if (sm) result = "60%";
      else result = "80%";

      return result;
    },
    imageHeight(): string {
      const { xs, sm, md, lg, xl, width } = this.$vuetify.breakpoint;
      let result = "";

      if (xs) result = "600";
      else if (sm || md || lg ) result = "920";
      else if (xl) result = "1024";
      if (width > 1920) result = "1024";

      return result;
    },
    gradient(): string {
      const { smAndDown } = this.$vuetify.breakpoint;
      let result = "";

      if (smAndDown) result = "to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.7)";

      return result;
    },
  },
});
