var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{attrs:{"options":{
    threshold: 0.5,
  },"min-height":"200"},model:{value:(_vm.triggerAnimation),callback:function ($$v) {_vm.triggerAnimation=$$v},expression:"triggerAnimation"}},[_c('v-img',{staticClass:"main-section",attrs:{"src":_vm.$vuetify.breakpoint.smAndDown
        ? '/img/truck-bg.jpg'
        : '/img/main-section-bg.png',"gradient":_vm.gradient,"height":_vm.imageHeight,"dark":""}},[_c('v-row',{staticClass:"\n        fill-height fill-width\n        pt-16\n        pl-4 pl-sm-8 pl-md-16 pl-lg-16 pl-xl-16\n      ",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-layout',{staticClass:"\n            fill-height fill-width\n            pr-2 pr-sm-4 pr-md-16 pr-lg-16 pr-xl-16\n            text-uppercase\n            font-weight-bold\n          ",attrs:{"column":"","align-start":"","justify-center":""}},[_c('div',{directives:[{name:"anime",rawName:"v-anime",value:(
              _vm.triggerAnimation && {
                scale: [1.5, 1],
                translateX: ['40%', 0],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                duration: 1500,
                delay: 500,
              }
            ),expression:"\n              triggerAnimation && {\n                scale: [1.5, 1],\n                translateX: ['40%', 0],\n                opacity: [0, 1],\n                easing: 'easeInOutExpo',\n                duration: 1500,\n                delay: 500,\n              }\n            "}],staticClass:"\n              text-h4 text-sm-h4 text-md-4 text-lg-h3 text-xl-h3\n              font-weight-bold\n              primary-text-color\n              mt-16\n              mb-4\n            "},[_c('p',[_vm._v(_vm._s(_vm.text))])]),_c('div',{directives:[{name:"anime",rawName:"v-anime",value:(
              _vm.triggerAnimation && {
                scale: [1.5, 1],
                translateX: ['40%', 0],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                duration: 1500,
                delay: 800,
              }
            ),expression:"\n              triggerAnimation && {\n                scale: [1.5, 1],\n                translateX: ['40%', 0],\n                opacity: [0, 1],\n                easing: 'easeInOutExpo',\n                duration: 1500,\n                delay: 800,\n              }\n            "}],staticClass:"\n              text-h4 text-sm-h4 text-md-4 text-lg-h3 text-xl-h3\n              mb-16\n              font-weight-bold\n            "},[_vm._v(" "+_vm._s(_vm.$t("labelBrand"))+" ")]),_c('img',{directives:[{name:"anime",rawName:"v-anime",value:(
              _vm.triggerAnimation && {
                translateX: ['-200%', 0],
                opacity: [0, 1],
                easing: 'easeInOutExpo',
                duration: 1500,
                delay: 1200,
              }
            ),expression:"\n              triggerAnimation && {\n                translateX: ['-200%', 0],\n                opacity: [0, 1],\n                easing: 'easeInOutExpo',\n                duration: 1500,\n                delay: 1200,\n              }\n            "}],attrs:{"src":"/img/truck-blue.png","alt":"truck image","width":_vm.truckImageWidth}})])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"6"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }